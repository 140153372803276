<template>
	<!-- 单词速记 -->
	<div class="com_conbox">
		<!-- 音频 -->
		<audio controls ref="commonaudioCom" class="disnone">
			<source src="" />
		</audio>
		<div class="imgclose" @click="handleclose">
			<img class="img100" src="../../assets/static/Frame3465600.png"/>
		</div>
		<!-- 音频 -->
		<div class="content-panel">
			<div class="block30"></div>
			<div class="xuexibox">
				<div class="title1"><img class="img100" src="../../assets/static/dck.png"/></div>
				<div class="wordbox">
					<div class="wordtext">
						{{wordCard.word || "word"}}
					</div>
					<div class="linsten-block">
						<div class="linsten-btn" @click="playAudioCom(wordCard.phAmMp3)">
							<img class="img100" src="@/assets/static/linsten.png"/>
						</div>
						<span v-if="wordCard.phAm">美 /{{wordCard.phAm}}/</span>
					</div>
				</div>
				<!-- 音标列表 -->
				<div class="say-list" v-if="syllables.length>0">
					<div class="say-item" v-if="syllables[0]" :style="{color: colorlist[0]}">{{syllables[0]}}</div>
					<template v-for="(item,index) in syllables" v-if="index != 0">
						<div :key="index" class="line">-</div>
						<div class="say-item" v-if="index<=5" :style="{color: colorlist[index]}">{{item}}</div>
						<div class="say-item" v-if="index>5" :style="{color: colorlist[0]}">{{item}}</div>
					</template>
				</div>
				<div class="block20"></div>
				<!-- 词态 -->
				<div class="wordTpyebox">
					<div class="desc-item" v-for="(sitem,index) in wordTpye" :key="index" v-if="sitem.part">
						<span>{{ sitem.part }}</span>
						<span class="desc2" v-for="(mitem, mindex) in sitem.means"
								:key="mindex">{{ mitem }};<span v-html="'&nbsp;'"></span>
						</span>
					</div>
				</div>
				
			</div>
			
			
		</div>
	</div>
	<!-- end-->
</template>

<script>
	export default {
		props: {
			wordId: {
				type: Number,
				default: () => {
					return 0;
				}
			},
			wordobj: {
				type: Object,
				default: () => {
					return {};
				}
			},
		},
		watch: {
			wordId: {
				immediate:true,
			    handler(newVal, objVal) {
					if(newVal){
						this.wordCard = {}
						this.uid = newVal
						this.getdetail()
					}
			    },
			},
			wordobj: {
				immediate:true,
			    handler(newVal, objVal) {
					if(newVal){
						this.wordCard = {}
						this.setwordobj(newVal)
					}
			    },
			},
		},
		data() {
			return {
				isLinstening: false, //是否正在播放音频
				wordTpye: [],//释义 n.动词
				syllables:[],//音节
				exchange:{},//词态
				sentence:[],//例句
				phrase:[],//词组搭配
				synonym:[],//近义词
				exchangetext:{
					word_pl:"复数",
					word_third:"三单",
					word_past:"过去式",
					word_done:"过去分词",
					word_ing:"现在分词",
					word_adj:"形容词",
					word_er:"比较级",
					word_est:"最高级",
					word_noun:"名词",
				},//词态
				wordCard:{},//当前学习的下标
				wordindex:0,//当前学习的下标
				awtitloading: false, //防止多次点击
				colorlist:["#0EEC9C","#FFBC0F","#0EB7EC","#AA56FF","#FF434E","#FF439D","#0E82EC"],
			};
		},
		methods: {
			//播放音频
			playAudioCom(src) {
				var _this = this
				try{
					_this.$refs.commonaudioCom.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudioCom.load()
					    _this.$refs.commonaudioCom.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//关闭
			handleclose(){
				this.$emit('onClose')
			},
			//详情
			getdetail(){
				var _this = this
				var params = {
					id: this.uid
				}
				this.$http.post('app/word/words/detail', params).then(function(res) {
					if (res.code == 200) {
						_this.setwordobj(res.data)
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})			
			},
			//加载数据数据显示
			setwordobj(wordCard){
				if(this.awtitloading){
					return;
				}
				if(wordCard&&wordCard.word){
					this.awtitloading = true
					try{
						this.wordTpye =  wordCard.wordTpye || JSON.parse(wordCard.definition)
					}catch(e){
						this.wordTpye = []
					}
					if(wordCard.exchange){
						try{
							this.exchange = JSON.parse(wordCard.exchange)
						}catch(e){
							this.exchange = ''
						}
					}
					
					try{
						this.syllables = wordCard.syllables.split('#')
					}catch(e){
						this.syllables = []
					}
					if(wordCard.sentence){
						//例句
						try{
							this.sentence = JSON.parse(wordCard.sentence)
						}catch(e){
							this.sentence = []
						}
						this.sentence.forEach((item, index) => {
							item["name"] = this.join(item.en,wordCard.word)
						});
					}
					//词组搭配
					if(wordCard.phrase){
						try{
							this.phrase = JSON.parse(wordCard.phrase)
						}catch(e){
							this.phrase = []
						}
					}
					
					//近义词
					if(wordCard.synonym){
						try{
							this.synonym = JSON.parse(wordCard.synonym)
						}catch(e){
							this.synonym = []
						}
					}
					this.wordCard = wordCard
					this.awtitloading = false
				}else{
					this.$message.error("单词卡不存在，请返回重试")
				}
			},
			// 拼接
			join(str,key){
				var reg = new RegExp((`(${key})`), "gm");
				//var replace = `<span class="sentence-wrod-style">I need some children's <span class="sentence-wrod-style-block"><div class="sentence-key-wrod-style"></div><span class="sentence-key-wrod-text">crayons</span></span>.</span>`
				var replace = '<span class="sentence-wrod-style-block"><span class="sentence-key-wrod-style"></span><span class="sentence-key-wrod-text" style="font-weight:bold;">$1</span></span>';
				return str.replace(reg, replace);
			},
			
		},
	};
</script>

<style lang="scss" scoped>
	/deep/ .el-input__inner{
		border: none;
	}
	.com_conbox {
		width: 100%;
		position: relative;
		z-index: 18;
		border-radius: 40px;
		background: linear-gradient(180deg, #63E0FF 0%, #24A7EF 100%);
		box-shadow: 0px -8px 4px 0px #2FC3EA inset;
		.imgclose{
			width: 40px;
			height: 40px;
			position: absolute;
			right: 32px;
			top: 28px;
			z-index: 2;
			cursor: pointer;
		}
	}
	.b_titlebox{
		width: 263px;
		height: 80px;
		border-radius: 20px;
		background: linear-gradient(90deg, #E8F0FB 0%, #99B8E3 100%);
		box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: -18px;
		top: -28px;
		z-index: 2;
		.box1{
			width: 231px;
			height: 62px;
			border-radius: 20px;
			background: linear-gradient(180deg, #2BB9FF 0%, #06F 100%);
			box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
			display: flex;
			align-items: center;
			justify-content: center;
			.span{
				color: #FFF;
				text-align: center;
				font-size: 32px;
			}
		}
	}
	.content-panel {
		width: 100%;
		min-height: 678px;
		position: relative;
		z-index: 1;
		padding: 28px 32px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		.startbox{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			.b_titlebox{
				position: relative !important;
				left: 0;
				top: 0;
			}
			.imgbox{
				width: 82%;
				height: 185px;
				margin-top: 44px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: radial-gradient(143.33% 75.16% at 17.95% 93.94%, #80ADFF 0%, #6FD9FE 100%);
				.icon{
					width: 210px;
					height: 137px;
				}
			}
			.title1{
				margin-top: 32px;
				color: #FFF;
				text-align: center;
				font-size: 32px;
			}
			.btn1{
				margin-top: 48px;
				width: 160px;
				height: 48px;
				color: #0772FF;
				line-height: 48px;
				text-align: center;
				border-radius: 25px;
				background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
				font-size: 20px;
				cursor: pointer;
			}
		}
		.xuexibox{
			width: 82%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			.title1{
				width: 144px;
				height: 63px;
			}
			.wordbox{
				width: 100%;
				height: 64px;
				display: flex;
				align-items: center;
				.wordtext{
					color: #273B90;
					font-size: 32px;
				}
				.linsten-block{
					flex: 1;
					margin-left: 38px;
					display: flex;
					align-items: center;
					.linsten-btn{
						width: 48px;
						height: 48px;
						cursor: pointer;
						flex-shrink: 0;
						margin-right: 8px;
					}
					.text{
						flex: 1;
						margin-left: 8px;
						color: #273B90;
						font-size: 24px;
					}
				}
			}
			.say-list{
				width: 100%;
				padding: 38px 0;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: #FFF;
				.say-item {
					font-size: 64px;
				}
				.line {
					margin: 0 8px;
					color: #273B90;
					font-size: 64px;
				}
			}
			.wordTpyebox{
				width: 100%;
				padding: 28px 58px;
				box-sizing: border-box;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: #FFF;
				.youkonw{
					color: #222;
					text-align: center;
					font-size: 20px;
				}
				.desc-item {
					display: flex;
					flex-wrap: wrap;
					margin-top: 16px;
					line-height: 18px;
					font-size: 16px;
					.desc2{
						margin-left: 5px;
					}
					span:last-child {
						color: #222222;
						margin-right: 5px;
					}
				}
			}
			.csbottombox{
				width: 100%;
				margin-top: 28px;
				display: flex;
				align-items: center;
				.left{
					color: #FFF;
					font-size: 24px;
					.text2,.text3{
						margin-top: 10px;
					}
				}
				.right{
					margin-left: auto;
					.btn1{
						width: 160px;
						height: 48px;
						color: #0772FF;
						line-height: 48px;
						text-align: center;
						border-radius: 25px;
						background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
						font-size: 20px;
						cursor: pointer;
					}
				}
			}
			.btnshow{
				margin-left: 8px;
				width: 112px;
				height: 42px;
				color: #FFFFFF;
				line-height: 42px;
				text-align: center;
				border-radius: 12px;
				background: #3773F5;
				font-size: 20px;
				cursor: pointer;
			}
			.buchongbox{
				width: 100%;
				height: 168px;
				padding: 28px 58px 0;
				box-sizing: border-box;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: #FFF;
				.wordbc{
					width: 100%;
					display: flex;
					align-items: center;
					color: #222;
					font-size: 20px;
				}
				.wordbc1{
					height: 58px;
					width: 100%;
					display: flex;
					align-items: center;
					color: #222;
					font-size: 20px;
					.text1{
						display: flex;
						align-items: center;
						font-size: 20px;
						span{
							color: #F50;
						}
					}
				}
				.inputlistbox{
					display: flex;
					align-items: center;
					.itemobj{
						display: flex;
						align-items: center;
						font-size: 32px;
						color: #FF5500;
						height: 42px;
						.text{
							margin: 0 5px;
						}
						.input{
							width: 38px;
							color: #FF5500;
							height: 42px;
							line-height: 42px;
							border: none;
							border-bottom: 1px solid #3773F5;
							text-align: center;
							font-size: 32px;
						}
					}
				}
			}
		}
	}
	
</style>