<template>
	<div class="page">
		<div class="w1200 need_page">
			<!-- 音频 -->
			<audio controls ref="commonaudioCom" class="disnone">
				<source src="" />
			</audio>
			<div class="block48"></div>
			<div class="need_box_one">
				<div class="titilebox">
					<div class="centerbox">
						<div class="backicon pointer" @click="navigato('/',{})"><img class="img100" src="../assets/static/left_go.png"/></div>
						<div class="line"></div>
						<div class="text">错词集合</div>
					</div>
					<div class="titlename_box">
						<div class="itemobj fontsize16" @click="handlebookdtype(index)" :class="{'active':index==bookdtypeindex}" v-for="(item,index) in bookdtypeList" :key="index">
							{{item}}
						</div>
					</div>
					<div class="centerbox"></div>
				</div>
				<div class="needconbox">
					<div class="datalist">
						<div class="itemobj" v-for="(item,index) in dataList" :key="index" @click="handleUnit(item)">
							<div class="leftbox" @click="playAudioCom(item.phAmMp3)">
								<img class="img100" src="../assets/static/linsten_small.png"/>
							</div>
							<div class="rightbox">
								<div class="text1 clamp">{{item.word}}</div>
								<div class="word-desc clamp" v-if="item.wordTpye.length>0">
									<span>{{ item.wordTpye[0].part }}</span>
									<span class="desc2" v-for="(mitem, mindex) in item.wordTpye[0].means"
										:key="mindex">{{ mitem }};<span v-html="'&nbsp;'"></span>
									</span>
								</div>
							</div>
						</div>
					</div>
					<!-- 空 -->
					<div class="data_empty_box" style="padding-top: 68px;" v-if="dataList.length<=0">
						<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
						<div class="text">暂无数据</div>
					</div>
					<!-- 空 -->
					<div class="paginationbox">
						<div class="btn1" @click="handlecuociexcel">保存错词本</div>
					    <el-pagination
					      @current-change="handleCurrentChange"
					      :current-page.sync="formData.currentPage"
					      :page-size="formData.pageSize"
					      layout="prev, pager, next, jumper"
					      :total="total">
					    </el-pagination>
					</div>
					<div class="block30"></div>
				</div>
			</div>
			
		</div>
		
		<!-- 弹窗 -->
		<div class="show_tc_box" v-if="showTcType>0">
			<div class="zezhao10"></div>
			<div class="w1200" v-if="showTcType==1">
				<wordCard @onClose="handleclosefun" :wordobj="wordCard"></wordCard>
			</div>
			<!-- 词义连连 -->
			<div class="w1200" v-if="showTcType==2">
				<wordLink :type="1" :list="wordList" @onClose="handleclosefun" @comfirm="studyComfirm"></wordLink>
			</div>
		</div>
		

		<div class="block30"></div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	import wordCard from "@/components/wordCard/wordCard.vue";
	import wordLink from "@/components/wordCard/wordLink.vue";
	export default {
		components:{
			wordCard,
			wordLink
		},
		data() {
			return {
				type:0,//1是听音读写， 2是从错词来的
				bookdtypeList:["词义连连","错词闯关","随记本本"],
				bookdtypeindex:2,//下标
				dataList:[],
				total:0,
				formData: {
					currentPage: 1,
					pageSize: 9,
				},
				showTcType:0,//弹窗
				wordCard:{},//当前学习的下标
				wordList:[],//列表
			};
		},
		mounted: function() {
			//获取数据
			this.getdatalist()
		},
		computed: {
			...mapState(["userInfo", "isLogin", "myReadingObj",'studyingData']),
		},
		methods: {
			...mapMutations(["SET_Data","SET_USERINFO"]),
			navigato(url,query) {
				this.$util.routerPath(url,query)
			},
			//导出错词本
			async handlecuociexcel(){
				var params = {
					userUuid:this.userInfo.uuid
				}
				showLoading()
				var res = await this.$common.postAjaxJsonexcel('app/word/userErrWord/exportErrWord',params)
				hideLoading()
				if(res){
					const blob = new Blob([res]);
					const fileName = '我的错词本.xls';
					const elink = document.createElement('a');
					elink.download = fileName;
					elink.style.display = 'none';
					elink.href = URL.createObjectURL(blob);
					document.body.appendChild(elink);
					elink.click();
					URL.revokeObjectURL(elink.href); // 释放URL 对象
					document.body.removeChild(elink);
					this.$message({
						type: 'success',
						message: '导出完毕!'
					});
				}else{
					this.$message({
						type: 'error',
						message: '导出失败!'
					});
				}
				
			},
			//播放音频
			playAudioCom(src) {
				var _this = this
				try{
					_this.$refs.commonaudioCom.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudioCom.load()
					    _this.$refs.commonaudioCom.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//完成
			studyComfirm(){
				this.handleclosefun()
			},
			//关闭
			handleclosefun(){
				this.showTcType = 0
				this.wordList = []
			},
			//点击弹窗
			handleUnit(item){
				this.wordCard = item
				this.showTcType = 1
			},
			//获取数据
			getdatalist(){
				var _this = this
				//查询内容
				var params = this.formData
				params["userUuid"] = this.userInfo.uuid
				showLoading()
				this.$http.post('app/word/userErrWord/index2', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						var records = res.data.records;
						//获取类型json
						records.forEach((item, index) => {
							try{
								item["wordTpye"] =  JSON.parse(item.definition)
							}catch(e){
								item["wordTpye"] = []
							}
						});
						_this.total = res.data.total;
						_this.dataList = records
						//判断来源
						if(sessionStorage.getItem('handlecuo')){
							let typeindex = Number(sessionStorage.getItem('handlecuo'))
							sessionStorage.setItem("handlecuo", "");
							_this.getcuocilist(typeindex)
						}else{}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//点击页数
			handleCurrentChange(currentPage) {
				this.formData.currentPage = currentPage;
				this.getdatalist();
			},
			//下标
			handlebookdtype(type){
				if(type==0){
					this.getcuocilist(type)
				}else if(type==1){
					this.getcuocilist(type)
				}else{
					
				}
			},
			//错词列表
			getcuocilist(showtype){
				var _this = this
				//查询内容
				var params = {
					userUuid:this.userInfo.uuid,
					status:0,//是否打乱顺序 0打乱 1不打乱
					wordNum:999
				}
				showLoading()
				this.$http.post('app/word/userErrWord/userErrWordList', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						var records = res.data;
						_this.wordList = records
						if(records.length<=0){
							_this.$alert('暂无错词数据！', '温馨提示', {
								confirmButtonText: '确定',
								callback: action => {
									
								}
							});
							return;
						}
						//判断训练类型
						if(showtype==0){
							_this.showTcType = 2
						}else{
							var studyingData = _this.studyingData
							studyingData.successwords = _this.wordList
							_this.SET_Data({ key: 'studyingData', value:studyingData})
							_this.$util.routerPath('/spellPage',{type:2})
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			
			
		},
	};
</script>
<style lang="scss" scoped>
	/deep/ .el-pagination button{
		background-color: transparent !important;
		font-size:15px !important;
	}
	/deep/ .el-pager li{
		background: transparent !important;
		font-size:15px !important;
	}
	.need_page{
		position: relative;
		z-index: 2;
	}
	.need_box_one{
		width: 100%;
		padding: 0 22px;
		box-sizing: border-box;
		position: relative;
		border-radius: 20px;
		border: 4px solid rgba(255, 255, 255, 0.00);
		background: linear-gradient(180deg, #FFF 16.74%, #F2F5FA 60.03%, #E1EAFB 99.98%);
		.titilebox{
			width: 100%;
			padding: 0 10px;
			box-sizing: border-box;
			height: 92px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.centerbox{
				width: 158px;
				display: flex;
				align-items: center;
				.backicon{
					width: 32px;
					height: 32px;
					margin-right: 12px;
					flex-shrink: 0;
				}
				.line{
					width: 4px;
					height: 20px;
					border-radius: 4px;
					background: #3773F5;
				}
				.text{
					margin-left: 8px;
					color: #222;
					font-size: 24px;
				}
			}
			.titlename_box{
				display: flex;
				align-items: center;
				.itemobj{
					width: 118px;
					margin: 0 10px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					border-radius: 6px;
					border: 1px solid #E0E0E0;
					background: #ffffff;
					color: #666;
					cursor: pointer;
				}
				.active{
					border: 1px solid #3773F5;
					background: #3773F5;
					color: #ffffff;
				}
			}
		}
		.needconbox{
			width: 100%;
			height: 600px;
			overflow-y: auto;
			.datalist{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				.itemobj{
					width: 358px;
					margin: 10px;
					padding: 25px 10px;
					box-sizing: border-box;
					display: flex;
					align-items: flex-start;
					border-radius: 12px;
					border: 1px solid #1E86FD;
					background: #FFF;
					box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10);
					cursor: pointer;
					.leftbox{
						width: 33px;
						height: 33px;
						margin-top: 2px;
						flex-shrink: 0;
					}
					.rightbox{
						flex: 1;
						margin-left: 8px;
						overflow: hidden;
						.text1{
							color: #222;
							font-size: 24px;
						}
						.text2{
							color: #333;
							font-size: 20px;
						}
						.word-desc {
							width: 100%;
							margin-left: 8px;
							color: #333;
							font-size: 20px;
							.desc2{
								margin-left: 4px;
							}
						}
					}
				}
				
			}
			.paginationbox{
				width: 100%;
				padding: 18px 0;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 20px;
				.btn1{
					width: 118px;
					margin: 0 10px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					border-radius: 6px;
					border: 1px solid #3773F5;
					background: #3773F5;
					color: #ffffff;
					cursor: pointer;
				}
			}
		}
	}
</style>