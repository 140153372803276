import { render, staticRenderFns } from "./wordCard.vue?vue&type=template&id=eb25bd92&scoped=true"
import script from "./wordCard.vue?vue&type=script&lang=js"
export * from "./wordCard.vue?vue&type=script&lang=js"
import style0 from "./wordCard.vue?vue&type=style&index=0&id=eb25bd92&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb25bd92",
  null
  
)

export default component.exports